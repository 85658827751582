import React, { useLayoutEffect, useRef, useState } from "react";
import Page from "../components/Common/Page";
import IndexLayout from "../layouts";
import PictureSection from "../components/pages/TeamPage/PictureSection";
import TextSection from "../components/pages/TeamPage/TextSection";
import DataSection from "../components/pages/TeamPage/DataSection";
import photo from "../assets/pages/team/People.png";
import VorapatPhornprapha from "../assets/pages/team/VorapatPhornprapha.png";
import SirasitPraneenij from "../assets/pages/team/SirasitPraneenij.png";
// import SiwapornPraneenig from "../assets/pages/team/SiwapornPraneenig.png";
import WiphawadeeT from "../assets/pages/team/WiphawadeeT.png";
import BussakornP from "../assets/pages/team/BussakornP.png";
import NatthanichaT from "../assets/pages/team/NatthanichaT.png";
import SittiponS from "../assets/pages/team/SittiponS.png";
import MockProfile from "../assets/pages/team/MockProfile.png";

export type FirstSectionProps = {
  picture: string;
  name: string;
  surname: string;
  subPosition: string
  position: string | undefined
  experience: string;
};

const FirstSectionData: FirstSectionProps[] = [
  {
    picture: VorapatPhornprapha,
    name: "Vorapat",
    surname: "Phornprapha",
    position: "CO-CEO",
    subPosition: "Cultivation",
    experience: "Experienced in design and technology integration, cultivation and operations",
  },
  {
    picture: SirasitPraneenij,
    name: "Sirasit",
    surname: "Praneenij",
    position: "CO-CEO",
    subPosition: "Business Development",
    experience: "Specializes in cannabis business development, strategy and operations.",
  },
  {
    picture: MockProfile,
    name: "Siwaporn",
    surname: "Praneenij, M.D.",
    position: undefined,
    subPosition: "Medical Advisor",
    experience: "Licensed medical cannabis doctor, researcher, and anti-aging specialist.",
  },
];

const SecondSectionData: FirstSectionProps[] = [
  {
    picture: WiphawadeeT,
    name: "Wipawadee",
    surname: "T.",
    position: undefined,
    subPosition: "General Manager",
    experience: "",
  },
  {
    picture: BussakornP,
    name: "Bussakorn",
    surname: "P.",
    position: undefined,
    subPosition: "Cultivation Manager",
    experience: "",
  },
  {
    picture: NatthanichaT,
    name: "Natthanicha",
    surname: "T.",
    position: undefined,
    subPosition: "Senior Cultivator",
    experience: "",
  },
  {
    picture: SittiponS,
    name: "Sittipon",
    surname: "S.",
    position: undefined,
    subPosition: "Cultivator",
    experience: "",
  },
];

const defaultShow = {
  itemOne: false,
  itemTwo: false,
  itemThree: false,
  itemFour: false,
  itemFive: false,
  itemSix: false,
  itemSeven: false,
};

function Team() {
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState<{ [key: string]: boolean }>({
    ...defaultShow,
    navbar: false,
  });

  const [activeStep, setActiveStep] = useState("#banner");

  const refNavbar = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);

  useLayoutEffect(() => {
    const bottomPos = (element: any) => element?.getBoundingClientRect().bottom + window.scrollY;

    const section1Bottom = bottomPos(refOne.current);
    const section2Bottom = bottomPos(refTwo.current);
    const section3Bottom = bottomPos(refThree.current);
    const section4Bottom = bottomPos(refFour.current);

    const onScroll = () => {
      if (window) {
        const scrollPos = window.scrollY + window.innerHeight;
        // Inside Nav
        // if (window.scrollY < 16) {
        //   handleSetShow("itemOne", true);
        // }
        // Section1
        if (scrollPos < window.innerHeight + 16) {
          if (!show["itemOne"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemOne"]: true,
              ["itemTwo"]: true,
              navbar: false,
            }));
            setActiveStep("#banner");
          }
          // const percent = Math.round(((scrollPos - window.innerHeight) / (section1Bottom - window.innerHeight)) * 100);
        }
        // Section2
        else if (window.innerHeight + 16 < scrollPos && scrollPos < section2Bottom + 250) {
          if (!show["itemTwo"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemTwo"]: true,
              navbar: true,
            }));
            setActiveStep("#productlist");
          }
        }
        // Section3
        else if (section2Bottom + 250 <= scrollPos && scrollPos < section3Bottom + 250) {
          if (!show["itemThree"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemThree"]: true,
              navbar: true,
            }));
            setActiveStep("#treeinformation");
          }
        }
        // Section4
        else if (section3Bottom + 250 <= scrollPos && scrollPos < section4Bottom + 250) {
          if (!show["itemFour"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemFour"]: true,
              navbar: true,
            }));
            setActiveStep("#somethingtoread");
          }
        }
      }
    };

    const initialProp = () => {
      onScroll();
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll);
    };

    const changeCheck = () => {
      setCheck(!check);
    };

    if (window) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("resize", changeCheck);
      initialProp();
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", changeCheck);
    };
  }, [check]);

  return (
    <IndexLayout ref={refNavbar} animate={show.navbar}>
      <Page>
        <PictureSection ref={refOne} animate={show.itemOne} />
        <TextSection ref={refTwo} animate={show.itemTwo} />
        <DataSection ref={refThree} animate={show.itemThree} data={FirstSectionData} title={"Executives"} />
        <DataSection ref={refFour} animate={show.itemFour} data={SecondSectionData} title={"Cultivation Team"} />
      </Page>
    </IndexLayout>
  );
}

export default Team;
