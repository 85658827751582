import React, { forwardRef } from "react";

interface TextSectionProps {
  className?: string;
  animate: boolean;
}

const TextSection = forwardRef(
  ({ className, animate }: TextSectionProps, ref: any) => {
    return (
      <section
        className="w-screen h-fit m-0 p-0 flex flex-col font-sans"
        ref={ref}
      >
        <p
          className="text-center text-medMainGreen text-2xl py-[140px] px-6 sm:px-10 md:px-[200px] lg:px-[342.5px] transition-all ease-out duration-2500 translate-y-10"
          style={
            animate
              ? { opacity: 1, transform: "translateZ(0px)" }
              : { opacity: 0 }
          }
        >
          With data oriented research and our team of experienced cultivators
          and researchers, it is our priority to bring to the market the highest
          quality cannabis products and premium consistent cultivars. As
          mentioned ‘god is in the details,’ in order to be the best in this
          field of horticulture business we make sure that professionalism,
          precision and methodical approach is the integral part of our
          framework.
        </p>
      </section>
    );
  }
);

export default TextSection;
