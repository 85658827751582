import React, { forwardRef } from "react";
import { FirstSectionProps } from "../../../pages/team";

interface DataSectionProps {
  className?: string;
  animate: boolean;
  data: FirstSectionProps[];
  title: string;
}

interface ItemProps {
  picture: string;
  name: string;
  surname: string;
  subPosition: string;
  position?: string;
  experience: string;
  animate: boolean;
}

function Item({ picture, name, surname, subPosition, position, experience, animate }: ItemProps) {
  return (
    <div
      className="col-span-12 md:col-span-6 lg:col-span-4 mt-4 pb-4 md:pb-[100px] lg:pb-[80px] flex flex-col items-center transition-all ease-out duration-2500 translate-y-10"
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
    >
      <img src={picture} alt="logo" className="w-60 h-60 rounded-[20px] bg-[#C5CCC3]" />
      <div className="text-medMainGreen text-center mt-4">
        <p className="text-[32px] leading-[36px]">{name} {surname}</p>
        <p className="text-[24px] leading-[30px] font-light">{position}</p>
        <p className="text-[24px] leading-[28px] font-light">({subPosition})</p>
      </div>
      <p className="text-medMainGreen text-2xl text-center mt-6 font-light">{experience}</p>
    </div>
  );
}

const DataSection = forwardRef(({ className, animate, data, title }: DataSectionProps, ref: any) => {
  return (
    <section className="box-border flex justify-center h-fit mb-10 px-6 md:px-10 lg:px-18" ref={ref}>
      <div className="w-full max-w-screen-xl bg-medEarthling rounded-[20px] lg:pb-10">
        <h3
          className="pt-20 pb-10 lg:pb-20 md:pb-0 text-h3 text-center text-medMainGreen transition-all ease-out duration-2500 translate-y-10"
          style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
        >
          {title}
        </h3>
        <div className="bg-primary-Earthling w-full h-[fit-content] grid grid-cols-12 gap-x-0 md:gap-x-14 px-[60px]">
          {data.map((item) => (
            <Item
              picture={item.picture}
              name={item.name}
              surname={item.surname}
              subPosition={item.subPosition}
              position={item.position}
              experience={item.experience}
              animate={animate}
            />
          ))}
        </div>
      </div>
    </section>
  );
});

export default DataSection;
