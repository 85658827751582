import React, { forwardRef } from "react";
import { BannerText } from "../../Common/BannerText";

interface PictureSectionProps {
  className?: string;
  animate: boolean;
}

const PictureSection = forwardRef(({ className, animate }: PictureSectionProps, ref: any) => {
  return (
    <section
      className="bg-medBgGray bg-cover w-screen h-fit flex justify-center h-[352px] sm:h-[200px] md:h-[300px] xl:h-[440px] "
      ref={ref}
    >
      <BannerText animate={animate}>Our Team</BannerText>
    </section>
  );
});

export default PictureSection;
